import React, { useEffect } from 'react';
import Layout from '../components/layout';


const NoMobileMarketplace = () => {

  const homePath = '/'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Layout>
      <section id='no-mobile' className='hero'>
        <div className="container d-flex flex-column justify-content-center" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
            <div className='hero-text-container'>
          <h2>The responsive version of the Silta financier portal is still being built.</h2>
          <p className='hero-subtitle'>To continue, please use a desktop browser.</p>
          <a href={homePath} className='back-btn'>
          <span className="material-icons">arrow_back</span>
            BACK TO HOME PAGE</a>
          </div>
          <div className="btn-hero-marketplace-outline--disabled">
            RESPONSIVE VERSION COMING SOON
          </div>
        </div>
      </section>
    </Layout>
}

export default NoMobileMarketplace